/* eslint-disable react/forbid-elements */
import './SurveyPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from '../Button';
import { Container } from '../Container';
import { SurveyPageContentType } from '../contentTypes';
import { HeaderA } from '../pagebuilder/HeaderA';
import { Page } from './Page';

export interface SurveyPageProps {}

export const SurveyPage = (props: SurveyPageProps) => {
  const page = b2x.usePage<SurveyPageContentType>();

  return (
    <Page className={classnames('survey-page')} noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.content?.body.surveyCode && (
        <Survey content={page.content.body} surveyCode={page.content.body.surveyCode} />
      )}
    </Page>
  );
};

interface SurveyProps {
  content?: SurveyPageContentType;
  surveyCode: string;
}

const Survey = ({ content, surveyCode }: SurveyProps) => {
  const { answers, nextQuestionButton, prevQuestionButton, question, resetButton, result, step, surveyInfo } =
    b2x.useSurvey(surveyCode);

  return (
    <>
      {!result?.completed && content?.inProgress?.header && <HeaderA mainComponent {...content.inProgress.header} />}
      {result?.completed && content?.complete?.header && <HeaderA mainComponent {...content.complete.header} />}
      <Container>
        <b2x.Div paddingY={5}>
          {surveyInfo?.details?.maxQuestionsNumber && (
            <SurveyNavigation current={step} steps={surveyInfo.details.maxQuestionsNumber} surveyInfo={surveyInfo} />
          )}
          {result?.completed ? (
            <SurveyResult content={content} resetButton={resetButton} surveyResult={result} />
          ) : (
            <>
              <SurveyQuestion question={question} />
              {answers && answers.length > 0 && (
                <b2x.Div marginBottom={5}>
                  <b2x.EqualHeight>
                    <b2x.Row gap={3} justifyContent={'center'}>
                      {answers.map((answer) => (
                        <b2x.Col key={answer.id} size={2}>
                          <SurveyAnswer answer={answer} />
                        </b2x.Col>
                      ))}
                    </b2x.Row>
                  </b2x.EqualHeight>
                </b2x.Div>
              )}
              <b2x.Div paddingBottom={5}>
                <b2x.Row gap={3} justifyContent={'center'}>
                  {step > 1 && (
                    <b2x.Col size={'auto'}>
                      <b2x.Button {...prevQuestionButton} variant="outline-primary" />
                    </b2x.Col>
                  )}
                  <b2x.Col size={'auto'}>
                    <b2x.Button {...nextQuestionButton} />
                  </b2x.Col>
                </b2x.Row>
              </b2x.Div>
            </>
          )}
        </b2x.Div>
      </Container>
    </>
  );
};

interface SurveyQuestionProps {
  question: b2x.contentTypes.SurveyQuestionContentType;
}

const SurveyQuestion = ({ question }: SurveyQuestionProps) => {
  return (
    <b2x.Div className={classnames('survey-question', `survey-question-id-${question.id}`)} marginBottom={5}>
      <b2x.Div className="title-container">
        <span className="title">{question.title}</span>
      </b2x.Div>
      <b2x.Div className="content-container">
        <span className="content">{question.content}</span>
      </b2x.Div>
    </b2x.Div>
  );
};

interface SurveyAnswerProps {
  answer: b2x.AnswerButtonProps;
}

const SurveyAnswer = ({ answer }: SurveyAnswerProps) => {
  return (
    <Button
      alignWithFlex={false}
      className={classnames('survey-answer', `survey-answer-id-${answer.id}`, { selected: answer.selected })}
      disabled={answer.disabled}
      onClick={answer.handleButtonClick}
      variant="blank"
    >
      {answer.asset && answer.asset.type?.length !== 0 && (
        <b2x.Div className="asset-container" marginBottom={4}>
          <b2x.EqualHeightElement name="asset">
            <b2x.AssetV2 className="asset" {...answer.asset} />
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
      {answer.title && (
        <b2x.Div className="title-container" marginBottom={answer.content ? 3 : 0}>
          <b2x.EqualHeightElement name="title">
            <span className="title">{answer.title}</span>
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
      {answer.content && (
        <b2x.Div className="content-container">
          <b2x.EqualHeightElement name="content">
            <span className="content">{answer.content}</span>
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
    </Button>
  );
};

interface SurveyNavigationProps {
  current: number;
  steps: number;
  surveyInfo?: b2x.SurveyApiDto<b2x.contentTypes.SurveyContentType>;
}

const SurveyNavigation = ({ current, steps }: SurveyNavigationProps) => {
  return (
    <b2x.Div className="survey-navigation" display={'flex'} justifyContent={'center'}>
      <b2x.Div
        className="survey-navigation-container"
        display={'flex'}
        gap={2}
        justifyContent={'center'}
        marginBottom={5}
      >
        {Array.from({ length: steps }, (_, index) => (
          <b2x.Div
            alignItems={'center'}
            className={classnames('survey-navigation-step', { active: current === index + 1 })}
            display={'flex'}
            justifyContent={'center'}
            key={index}
          >
            {index + 1}
          </b2x.Div>
        ))}
      </b2x.Div>
    </b2x.Div>
  );
};

interface SurveyResultProps {
  content?: SurveyPageContentType;
  resetButton: b2x.ButtonProps<string, string, number>;
  surveyResult?: b2x.SurveyResultApiDto;
}

const SurveyResult = ({ content, resetButton, surveyResult }: SurveyResultProps) => {
  const resultValue = Number(surveyResult?.result?.POINTS ?? 0);

  const result = content?.complete?.results?.find(
    (_result) => resultValue > Number(_result.valueFrom) && resultValue < Number(_result.valueTo)
  );
  const length = result?.items?.length ?? 0;

  return (
    <b2x.Div className="survey-result">
      <b2x.Div marginBottom={5}>
        <b2x.EqualHeight>
          <b2x.Row gap={5} justifyContent={'center'}>
            {result?.content && (
              <b2x.Col size={8}>
                <b2x.Div textAlign={'center'}>{b2x.formatHtml(result.content)}</b2x.Div>
              </b2x.Col>
            )}
            <b2x.Col size={12}>
              <b2x.Row gap={3}>
                {result?.items?.map(({ asset, contentSectionId, product }) => (
                  <b2x.Col key={contentSectionId} size={''}>
                    <b2x.Row gap={3}>
                      {product && (
                        <b2x.Col
                          className={classnames({ 'order-2': length === 1 }, { 'order-1': length > 1 })}
                          size={length > 1 ? 12 : 4}
                        >
                          <b2x.ProductsByIds ids={[product]}>
                            {(products) => (
                              <b2x.Listing name="Survey result product" products={products}>
                                {products?.map((_product) => (
                                  <b2x.ProductTile key={_product.id} product={_product} />
                                ))}
                              </b2x.Listing>
                            )}
                          </b2x.ProductsByIds>
                        </b2x.Col>
                      )}
                      {asset && (
                        <b2x.Col
                          className={classnames({ 'order-1': length === 1 }, { 'order-2': length > 1 })}
                          size={length > 1 ? 12 : 8}
                        >
                          <b2x.AssetV2 {...asset} />
                        </b2x.Col>
                      )}
                    </b2x.Row>
                  </b2x.Col>
                ))}
              </b2x.Row>
            </b2x.Col>
            <b2x.Col display={'flex'} justifyContent={'center'} size={12}>
              <b2x.Button {...resetButton} label={'Ripeti il test'} variant="outline-primary" />
            </b2x.Col>
          </b2x.Row>
        </b2x.EqualHeight>
      </b2x.Div>
    </b2x.Div>
  );
};
