import {
  SurveyApiDto,
  SurveyQuestionApiDto,
  SurveyResultApiDto,
  SurveySessionApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useSurveyApi } from './api/useSurveyApi';
import { ButtonProps } from './Button';
import {
  AssetContentSectionV2,
  SurveyAnswerContentType,
  SurveyContentType,
  SurveyQuestionContentType,
} from './contentTypes';
import { t } from './i18n/i18n';
import { storage } from './storage';

export interface UseSurveyProps {
  surveyCode: string;
}

export interface AnswerButtonProps {
  asset?: AssetContentSectionV2;
  content?: string;
  disabled?: boolean;
  handleButtonClick?(): void;
  id: string;
  selected: boolean;
  title: string;
}

export const useSurvey = (surveyCode: string) => {
  const { getSurveyInfo, getSurveySession, getSurveySessionResult, initSurveySession, setSurveySessionAnswer } =
    useSurveyApi();

  const { getBoolean, removeItem, setBoolean } = storage;

  const storageName = 'SURVEY_INIT';

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Stato del sondaggio

  const [loading, setLoading] = React.useState<boolean>(true);

  const [surveyInfo, setSurveyInfo] = React.useState<SurveyApiDto<SurveyContentType>>();
  const [surveySession, setSurveySession] =
    React.useState<SurveySessionApiDto<SurveyQuestionContentType, SurveyAnswerContentType, SurveyContentType>>();
  const [surveyStep, setSurveyStep] = React.useState<
    SurveyQuestionApiDto<SurveyQuestionContentType, SurveyAnswerContentType> | undefined
  >();
  const [result, setResult] = React.useState<SurveyResultApiDto>();

  const [step, setStep] = React.useState<number>(1);
  const [prevStepIndex, setPrevStepIndex] = React.useState<number>(-1);

  const [idResponse, setIdResponse] = React.useState<string>();
  const [idQuestion, setIdQuestion] = React.useState<string>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero l'esito del sondaggio

  const getResult = React.useCallback(() => {
    surveySession?.id &&
      getSurveySessionResult({ id: surveySession.id })
        .then((response) => {
          setResult(response.data);
        })
        .catch((response) => {
          console.log('Si è verificato un problema con la getSurveySessionResult');
        });
  }, [getSurveySessionResult, surveySession?.id]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero le informazioni generiche del sondaggio

  const getInfo = React.useCallback(() => {
    getSurveyInfo({ code: surveyCode, populate: { content: true, details: true } })
      .then((response) => {
        setSurveyInfo(response.data);
      })
      .catch((response) => {
        console.log('Si è verificato un problema con la getSurveyInfo');
      });
  }, [getSurveyInfo, surveyCode]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero le informazioni del sondaggio (domanda, risposte, ...)

  const getSurvey = React.useCallback(() => {
    getSurveySession({
      code: surveyCode,
      populate: {
        answeredQuestions: {
          availableResponses: { content: true },
          content: true,
          selectedResponse: { content: true },
        },
        nextQuestion: {
          availableResponses: { content: true },
          content: true,
          selectedResponse: { content: true },
        },
        survey: { details: true },
      },
    })
      .then((response) => {
        setSurveySession(response.data);
        setSurveyStep(response.data.nextQuestion);
        setIdQuestion(response.data.nextQuestion?.id);
        setStep(response.data.nextQuestion?.step ?? 1);
        setPrevStepIndex((response.data.nextQuestion?.step ?? 1) - 2);
        setLoading(false);
        setIdResponse('');
        response.data.completed && getResult();
      })
      .catch((response) => {
        console.log('Si è verificato un problema con la getSurveySession');
      });
  }, [getResult, getSurveySession, surveyCode]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Invio la risposta scelta dall'utente

  const sendAnswer = React.useCallback(
    (_idQuestion?: string, _idResponse?: string) => {
      setSurveySessionAnswer({
        idQuestion: _idQuestion ?? idQuestion,
        idResponse: _idResponse ?? idResponse,
        idSurveySession: surveySession?.id,
      })
        .then((response) => {
          setStep((prevState) => prevState + 1);
          setPrevStepIndex((prevState) => prevState + 1);
          getSurvey();

          step === surveyInfo?.details?.maxQuestionsNumber && getResult();
        })
        .catch((response) => {
          console.log('Si è verificato un problema con la setSurveyCustomerAnswer');
        });
    },
    [
      getResult,
      getSurvey,
      idQuestion,
      idResponse,
      setSurveySessionAnswer,
      step,
      surveyInfo?.details?.maxQuestionsNumber,
      surveySession?.id,
    ]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback di init

  const initSurvey = React.useCallback(() => {
    if (!getBoolean(storageName)) {
      initSurveySession({ forceNew: true, surveyCode: surveyCode })
        .then(() => {
          setBoolean(storageName, true);
          getInfo();
          getSurvey();
        })
        .catch(() => {
          console.log('errore durante la fase di inizializzazione del survey');
        });
    } else {
      getInfo();
      getSurvey();
    }
  }, [getBoolean, getInfo, getSurvey, initSurveySession, setBoolean, surveyCode]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inizializzazione del sondaggio

  React.useEffect(() => {
    initSurvey();
  }, [initSurvey]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per andare avanti nel sondaggio

  const handleNextQuestionButtonClick = React.useCallback(() => {
    setLoading(true);
    sendAnswer();
  }, [sendAnswer]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per tornare indietro nel sondaggio

  const handlePrevQuestionButtonClick = React.useCallback(() => {
    setSurveyStep(surveySession?.answeredQuestions?.at(prevStepIndex));
    setIdQuestion(surveySession?.answeredQuestions?.at(prevStepIndex)?.id);
    setIdResponse(surveySession?.answeredQuestions?.at(prevStepIndex)?.selectedResponse?.id);
    setPrevStepIndex((prevState) => prevState - 1);
    setStep((prevState) => prevState - 1);
  }, [prevStepIndex, surveySession?.answeredQuestions]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per resettare il sondaggio

  const handleResetButtonClick = React.useCallback(() => {
    setIdResponse('');
    setIdQuestion('');
    setStep(1);
    setPrevStepIndex(-1);
    removeItem(storageName);
    setResult(undefined);
    initSurvey();
  }, [initSurvey, removeItem]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Return del custom hook

  const allQuestionFromSurveyContent = surveyInfo?.content?.body.steps?.flatMap((_step) => _step.question);

  const question: SurveyQuestionContentType = allQuestionFromSurveyContent?.find(
    (_question) => surveyStep?.id === _question?.id
  ) ?? { content: surveyStep?.description, id: surveyStep?.id, title: surveyStep?.title };

  const allAnswerFromSurveyContent = surveyInfo?.content?.body.steps?.flatMap((_step) =>
    _step.answers?.flatMap((_answer) => ({ ..._answer }))
  );

  const answers = surveyStep?.availableResponses?.reduce<Array<AnswerButtonProps>>((newAnswers, currentAnswer) => {
    const _answerFromSurveyContent = allAnswerFromSurveyContent?.find(
      (_answerFromContent) => _answerFromContent?.answer?.id === currentAnswer.id
    )?.answer;

    const _title = currentAnswer.content?.body.title
      ? currentAnswer.content.body.title
      : _answerFromSurveyContent?.title ?? currentAnswer.title;

    const _asset = currentAnswer.content?.body.asset
      ? currentAnswer.content.body.asset
      : _answerFromSurveyContent?.asset;

    const _content = currentAnswer.content?.body.content
      ? currentAnswer.content.body.content
      : _answerFromSurveyContent?.content;

    const _selected = idResponse === currentAnswer.id;

    const _id = currentAnswer.id;

    newAnswers.push({
      asset: _asset,
      content: _content,
      disabled: loading,
      handleButtonClick: () => setIdResponse(_id),
      id: _id,
      selected: _selected,
      title: _title ?? '',
    });

    return newAnswers;
  }, []);

  const nextQuestionButton: ButtonProps<string, string, number> = {
    disabled: loading || !idResponse,
    label:
      step === surveyInfo?.details?.maxQuestionsNumber && idResponse && !loading
        ? t('survey.buttons.complete.label')
        : t('survey.buttons.nextQuestion.label'),
    onClick: handleNextQuestionButtonClick,
  };

  const prevQuestionButton: ButtonProps<string, string, number> = {
    disabled: loading || prevStepIndex === -1,
    label: t('survey.buttons.prevQuestion.label'),
    onClick: handlePrevQuestionButtonClick,
  };

  const resetButton: ButtonProps<string, string, number> = {
    label: t('survey.buttons.reset.label'),
    onClick: handleResetButtonClick,
  };

  return {
    answers,
    nextQuestionButton,
    prevQuestionButton,
    question,
    resetButton,
    result,
    step,
    surveyInfo,
  };
};
