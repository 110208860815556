import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

// import React from 'react';
import { Button, ButtonVariant } from './Button';
import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';
import { PriceBlock } from './PriceBlock';
import { ProducTiletStickers } from './ProductTileStickers';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = ({ product, ...otherProps }: ProductTileProps) => {
  const inStock = product.skus?.find((sku) => sku.state === 'AVAILABLE');

  return (
    <b2x.ProductTile
      className={classnames('card', { 'in-stock': inStock }, { 'out-of-stock': !inStock })}
      product={product}
      {...otherProps}
      enableExtraInfo
    />
  );
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = (props: ProductTileBasicInfoProps) => (
  <b2x.ProductTileBasicInfo {...props} customComponent={CustomProductTileBasicInfo} />
);

const CustomProductTileBasicInfo = ({
  colIndex,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: b2x.ProductTileBasicInfoProps) => {
  const productImage = sku?.image ?? product.image;
  const productAlternativeImage =
    (sku?.alternativeImages && sku.alternativeImages[0]) ?? (product.alternativeImages && product.alternativeImages[0]);

  const { discountPercentage } = b2x.usePrice(product.priceRange, sku?.price);

  return (
    <div className="product-tile-basic-info">
      <div className="position-relative">
        <div className="position-absolute top-0 start-0 w-100" style={{ pointerEvents: 'none', zIndex: 100 }}>
          <b2x.Row gap={0}>
            <b2x.Col size={''}>
              <div className="hstack flex-wrap">
                {discountPercentage && (
                  <div className="discount-percentage">
                    <div className="bg-primary extra-small border border-dark text-light fw-bold px-2 mt-2 mt-md-3">{`-${b2x.formatFloatPercentage(
                      discountPercentage
                    )}%`}</div>
                  </div>
                )}
                <ProducTiletStickers product={product} selectedVariant={product} />
              </div>
            </b2x.Col>
            <b2x.Col size={'auto'}>
              {b2x.appConfig.enableWishlist && (
                <b2x.WishlistButtonHelper product={product} sku={sku}>
                  {({ handleWishlistButtonClick, inWishlist }) => (
                    <Button
                      className="p-3"
                      iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 22 }}
                      onClick={handleWishlistButtonClick}
                      style={{ pointerEvents: 'initial' }}
                      type="button"
                      variant="blank"
                    />
                  )}
                </b2x.WishlistButtonHelper>
              )}
            </b2x.Col>
          </b2x.Row>
        </div>
        <b2x.router.Link to={product.url}>
          <div className="product-media-container">
            <b2x.EqualHeightElement
              colIndex={colIndex}
              name={productsPerRow ? `product-media-container-${productsPerRow}` : 'product-media-container'}
            >
              <div className="image">
                <b2x.ImageSwitcher
                  alt={product.name}
                  aspectRatio={b2x.appConfig.productImageAspectRatio}
                  fluid
                  format={imageFormat}
                  src={productImage?.src}
                />
              </div>
              {productImage && productAlternativeImage && (
                <div className="alternative-image">
                  <b2x.ImageSwitcher
                    alt={product.name}
                    aspectRatio={b2x.appConfig.productImageAspectRatio}
                    fluid
                    format={imageFormat}
                    src={productAlternativeImage.src}
                  />
                </div>
              )}
            </b2x.EqualHeightElement>
          </div>
        </b2x.router.Link>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  colIndex,
  fieldsHelper,
  priceHelper,
  product,
  productsPerRow,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  const inStock = product.skus?.find((sku) => sku.state === 'AVAILABLE');
  const productLine = product.attributes?.find((attribute) => attribute.typeCode === 'BIONK_LINEA')?.value;
  // const handleTryButtonClick = React.useCallback(() => {
  //   alert('Try button click');
  // }, []);

  const selectedSkuColor = selectedSku?.attributes?.find(
    (attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE'
  )?.value;

  const variantLabel = selectedSkuColor ? `${t('misc.variant.color')}: ${selectedSkuColor}` : t('misc.variant.size');

  return (
    <div className="product-tile-extra-info py-3">
      <b2x.router.Link className="text-decoration-none" to={product.url}>
        <div className="product-info-container">
          <b2x.EqualHeightElement colIndex={colIndex} name={`product-name-container-${productsPerRow}`}>
            <h3 className="fw-bold small mb-0">{product.name}</h3>
          </b2x.EqualHeightElement>
          <b2x.EqualHeightElement colIndex={colIndex} name={`product-secondary-info-container-${productsPerRow}`}>
            {productLine && <div className="extra-small">{productLine}</div>}
            <b2x.SummaryReviewsPreview productId={product.id} />
          </b2x.EqualHeightElement>
        </div>
      </b2x.router.Link>

      {inStock ? (
        <div>
          <div className="my-2">
            <b2x.EqualHeightElement colIndex={colIndex} name={`product-skus-variants-container-${productsPerRow}`}>
              <b2x.EqualHeightElement colIndex={colIndex} name={`product-variants-${productsPerRow}`}>
                <div className="product-variants mb-3">
                  {fieldsHelper.productVariants.formFields.length > 0 && (
                    <b2x.FormGroup
                      {...fieldsHelper.productVariants.formGroup}
                      className="hstack flex-wrap gap-2"
                      label={undefined}
                      noMarginBottom
                    >
                      {fieldsHelper.productVariants.formFields.map(({ productVariant, radio }) => {
                        const label =
                          productVariant.attributes
                            ?.find((attribute) => attribute.typeCode === 'BIONK_FORMATO')
                            ?.value?.toLocaleLowerCase() ?? '';

                        return <CustomRadio key={radio.id} label={label} radio={radio} type={'text'} />;
                      })}
                    </b2x.FormGroup>
                  )}
                </div>
              </b2x.EqualHeightElement>
              {fieldsHelper.skus.formFields.length > 1 && (
                <div className="product-skus mb-3">
                  <b2x.FormGroup
                    {...fieldsHelper.skus.formGroup}
                    label={variantLabel}
                    labelClassName="extra-small"
                    noMarginBottom
                  >
                    <b2x.SwiperContext>
                      {({ navigationNextElRef, navigationPrevElRef }) => {
                        return (
                          <b2x.Div className="slider-container">
                            <Button
                              className="prev"
                              iconStart={{ name: 'arrow-left-light' }}
                              innerRef={navigationPrevElRef}
                              justifyContent="start"
                              variant="blank"
                            />
                            <b2x.Swiper
                              navigation={{ custom: true }}
                              slides={fieldsHelper.skus.formFields.map(({ radio, sku }) => {
                                const imageUrl = sku.attributes?.find(
                                  (attribute) => attribute.typeCode === 'BIONK_ICONA_COLORE'
                                )?.value;

                                const label = imageUrl
                                  ? sku.attributes
                                      ?.find((attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE')
                                      ?.value?.toLocaleLowerCase() ?? ''
                                  : sku.attributes
                                      ?.find((attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE')
                                      ?.value?.toLocaleLowerCase() ??
                                    sku.name?.toLocaleLowerCase() ??
                                    '';

                                const type = imageUrl ? 'icon' : 'text';
                                return (
                                  <CustomRadio
                                    image={imageUrl ? { alt: label, title: label, url: imageUrl } : undefined}
                                    key={radio.id}
                                    label={label}
                                    radio={radio}
                                    type={type}
                                  />
                                );
                              })}
                              slidesPerView={'auto'}
                              spaceBetween={8}
                            />
                            <Button
                              className="next"
                              iconStart={{ name: 'arrow-right-light' }}
                              innerRef={navigationNextElRef}
                              justifyContent="end"
                              variant="blank"
                            />
                          </b2x.Div>
                        );
                      }}
                    </b2x.SwiperContext>
                  </b2x.FormGroup>
                </div>
              )}
            </b2x.EqualHeightElement>
          </div>
          {priceHelper && (
            <div className="product-price-container mb-3">
              <b2x.EqualHeightElement colIndex={colIndex} name={`product-price-container-${productsPerRow}`}>
                <b2x.Row alignItems={'center'} gap={3}>
                  <b2x.Col size={''}>
                    <PriceBlock hiddenDiscountPercentage priceHelper={priceHelper} />
                  </b2x.Col>
                  <b2x.Col size={'auto'}>
                    <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                      fieldsHelper={fieldsHelper}
                      selectedSku={selectedSku}
                      submitButton={{
                        className: 'py-2 px-3',
                        iconStart: { name: 'shopping-cart-full', size: 22 },
                        label: undefined,
                        variant: 'blank',
                      }}
                    />
                  </b2x.Col>
                </b2x.Row>
                <b2x.BestPrice priceHelper={priceHelper} />
              </b2x.EqualHeightElement>
            </div>
          )}
        </div>
      ) : (
        <b2x.Div marginY={3}>
          <b2x.Row alignItems={'center'} gap={2} justifyContent={'spaceAround'}>
            <b2x.Col className={'order-sm-2 d-grid'} size={{ sm: 'auto', xs: 12 }}>
              <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={{
                  className: 'py-2 px-3',
                  iconStart: { name: 'shopping-cart-full', size: 22 },
                  label: undefined,
                  variant: 'blank',
                }}
              />
            </b2x.Col>
            <b2x.Col className={'order-sm-1'} size={{ sm: '', xs: 12 }}>
              <div className="small fw-medium lh-sm">{t('misc.outOfStock')}</div>
            </b2x.Col>
          </b2x.Row>
        </b2x.Div>
      )}
      {/* <Button
        className="extra-small fw-normal"
        iconStart={{ name: 'try-rounded', size: 12 }}
        label={'Provalo subito'}
        onClick={handleTryButtonClick}
        variant="blank"
      /> */}
    </div>
  );
};
