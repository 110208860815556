import './CartPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Container } from '../Container';
import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export type CartPageProps = b2x.CartPageProps;

export const CartPage = (props: CartPageProps) => {
  return (
    <Page className="cart-page bg-gray-100">
      <section className="mb-5">
        <Container>
          <div className="text-center">
            <h1 className="h3 fw-regular text-uppercase m-0">{t('cart.title')}</h1>
          </div>
        </Container>
      </section>
      <b2x.CartPage
        {...props}
        cartSkusToMoveCampaignsCodes={['CAMPIONI-OMAGGIO']}
        summaryBottomInfo={<CartSummaryBottomInfo />}
      />
    </Page>
  );
};

interface CartSummaryBottomInfoProps {}

const CartSummaryBottomInfo = (props: CartSummaryBottomInfoProps) => {
  const miscContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT')?.body.cartPage;

  return (
    <React.Fragment>
      {(miscContent?.summaryBottomInfo?.title ||
        miscContent?.summaryBottomInfo?.content ||
        miscContent?.summaryBottomInfo?.disclaimer ||
        miscContent?.summaryBottomInfo?.image?.typeof) && (
        <b2x.Div className="cart-banner bg-white shadow-sm">
          {miscContent.summaryBottomInfo.image && (
            <b2x.ImageFromContentV2 className="w-100" fluid {...miscContent.summaryBottomInfo.image} />
          )}
          <b2x.Div className="p-3 bg-white text-center">
            {miscContent.summaryBottomInfo.title && (
              <b2x.P className="small fw-bold mb-0">{miscContent.summaryBottomInfo.title}</b2x.P>
            )}
            {miscContent.summaryBottomInfo.content && (
              <b2x.P className="extra-small mb-4">{miscContent.summaryBottomInfo.content}</b2x.P>
            )}
            {miscContent.summaryBottomInfo.disclaimer && (
              <b2x.P className="mb-0 extra-small">{miscContent.summaryBottomInfo.disclaimer}</b2x.P>
            )}
          </b2x.Div>
        </b2x.Div>
      )}
    </React.Fragment>
  );
};
