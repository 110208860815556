import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';
import { PageBuilderContentType } from '../contentTypes';
import { Icon } from '../Icon';
import { AccordionA } from '../pagebuilder/AccordionA';
import { AssetA } from '../pagebuilder/AssetA';
import { CopyA } from '../pagebuilder/CopyA';
import { CopyB } from '../pagebuilder/CopyB';
import { CopyC } from '../pagebuilder/CopyC';
import { CopyD } from '../pagebuilder/CopyD';
import { CopyE } from '../pagebuilder/CopyE';
import { DoubleColumnsA } from '../pagebuilder/DoubleColumnsA';
import { DownloaderA } from '../pagebuilder/DownloaderA';
import { HeaderA } from '../pagebuilder/HeaderA';
import { IconsA } from '../pagebuilder/IconsA';
import { IconsB } from '../pagebuilder/IconsB';
import { IconsC } from '../pagebuilder/IconsC';
import { IconsD } from '../pagebuilder/IconsD';
import { InstagramA } from '../pagebuilder/InstagramA';
import { MagazineA } from '../pagebuilder/MagazineA';
import { MultiColumnA } from '../pagebuilder/MultiColumnA';
import { MultiColumnB } from '../pagebuilder/MultiColumnB';
import { ProductsSliderDoubleColumnA } from '../pagebuilder/ProductsSliderDoubleColumnA';
import { ProductsSliderDoubleColumnB } from '../pagebuilder/ProductsSliderDoubleColumnB';
import { ProductsSliderSingleColumnA } from '../pagebuilder/ProductsSliderSingleColumnA';
import { SingleColumnA } from '../pagebuilder/SingleColumnA';
import { SliderA } from '../pagebuilder/SliderA';
import { TabsA } from '../pagebuilder/TabsA';
import { Page } from './Page';
import { usePopUpNewsletterModal } from './PopupNewsletterModal';

export interface PageBuilderProps {
  className?: string;
  demo?: boolean;
}

export const PageBuilder = ({ className, demo }: PageBuilderProps) => {
  const page = b2x.usePage<PageBuilderContentType>();

  const { pageReady } = b2x.useAppContext();

  const [PopupNewsLetterModal, showPopupNewsLetterModal] = usePopUpNewsletterModal({
    onClose: () => {
      const modalClosedTimes = (b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0) + 1;
      b2x.storage.setNumber('homePageNewsletterModalClosedTimes', modalClosedTimes, true);
    },
  });

  React.useEffect(() => {
    const newsletterConsent = b2x.storage.getBoolean('newsletterConsent', true);
    if (newsletterConsent) {
      return;
    }

    const modalClosedTimes = b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0;
    if (modalClosedTimes < 2 && pageReady) {
      b2x.wait(3000 * (modalClosedTimes + 1)).then(showPopupNewsLetterModal);
    }
  }, [pageReady, showPopupNewsLetterModal]);

  return (
    <Page
      className={classnames('page-builder', className)}
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[page]}
    >
      {page?.content?.body.showPopupSection?.showPopup && PopupNewsLetterModal}

      {page?.content?.body.sections?.map((section) => {
        let componentName;
        let component = null;

        const sectionComponent = b2x.getItemFromSwitch(section.component);

        if (sectionComponent?.assetA) {
          componentName = 'assetA';
          component = <AssetA {...sectionComponent.assetA} />;
        }

        if (sectionComponent?.accordionA) {
          componentName = 'accordionA';
          component = <AccordionA {...sectionComponent.accordionA} />;
        }

        if (sectionComponent?.sliderA) {
          componentName = 'sliderA';
          component = <SliderA {...sectionComponent.sliderA} />;
        }

        if (sectionComponent?.productsSliderSingleColumnA) {
          componentName = 'productsSliderSingleColumnA';
          component = (
            <ProductsSliderSingleColumnA
              {...sectionComponent.productsSliderSingleColumnA}
              mainComponent={section.options?.mainComponent}
            />
          );
        }

        if (sectionComponent?.productsSliderDoubleColumnA) {
          componentName = 'productsSliderDoubleColumnA';
          component = (
            <ProductsSliderDoubleColumnA
              {...sectionComponent.productsSliderDoubleColumnA}
              mainComponent={section.options?.mainComponent}
            />
          );
        }

        if (sectionComponent?.productsSliderDoubleColumnB) {
          componentName = 'productsSliderDoubleColumnB';
          component = (
            <ProductsSliderDoubleColumnB
              {...sectionComponent.productsSliderDoubleColumnB}
              mainComponent={section.options?.mainComponent}
            />
          );
        }

        if (sectionComponent?.copyA) {
          componentName = 'copyA';
          component = <CopyA {...sectionComponent.copyA} />;
        }

        if (sectionComponent?.copyB) {
          componentName = 'copyB';
          component = <CopyB {...sectionComponent.copyB} />;
        }

        if (sectionComponent?.copyC) {
          componentName = 'copyC';
          component = <CopyC {...sectionComponent.copyC} />;
        }

        if (sectionComponent?.copyD) {
          componentName = 'copyD';
          component = <CopyD {...sectionComponent.copyD} />;
        }

        if (sectionComponent?.copyE) {
          componentName = 'copyE';
          component = <CopyE {...sectionComponent.copyE} />;
        }

        if (sectionComponent?.singleColumnA) {
          componentName = 'singleColumnA';
          component = (
            <SingleColumnA {...sectionComponent.singleColumnA} mainComponent={section.options?.mainComponent} />
          );
        }

        if (sectionComponent?.doubleColumnsA) {
          componentName = 'doubleColumnsA';
          component = <DoubleColumnsA {...sectionComponent.doubleColumnsA} />;
        }

        if (sectionComponent?.multiColumnA) {
          componentName = 'multiColumnA';
          component = (
            <MultiColumnA {...sectionComponent.multiColumnA} mainComponent={section.options?.mainComponent} />
          );
        }

        if (sectionComponent?.multiColumnB) {
          componentName = 'multiColumnB';
          component = <MultiColumnB {...sectionComponent.multiColumnB} />;
        }

        if (sectionComponent?.iconsA) {
          componentName = 'iconsA';
          component = <IconsA {...sectionComponent.iconsA} />;
        }

        if (sectionComponent?.iconsB) {
          componentName = 'iconsB';
          component = <IconsB {...sectionComponent.iconsB} />;
        }

        if (sectionComponent?.iconsC) {
          componentName = 'iconsC';
          component = <IconsC {...sectionComponent.iconsC} />;
        }

        if (sectionComponent?.iconsD) {
          componentName = 'iconsD';
          component = <IconsD {...sectionComponent.iconsD} />;
        }

        if (sectionComponent?.instagramA) {
          componentName = 'instagramA';
          component = <InstagramA {...sectionComponent.instagramA} />;
        }

        if (sectionComponent?.headerA) {
          componentName = 'headerA';
          component = <HeaderA {...sectionComponent.headerA} />;
        }

        if (sectionComponent?.downloaderA) {
          componentName = 'downloaderA';
          component = <DownloaderA {...sectionComponent.downloaderA} />;
        }

        if (sectionComponent?.magazineA) {
          componentName = 'magazineA';
          component = <MagazineA {...sectionComponent.magazineA} />;
        }

        if (sectionComponent?.tabsA) {
          componentName = 'tabsA';
          component = <TabsA {...sectionComponent.tabsA} />;
        }

        return (
          <React.Fragment key={section.contentSectionId}>
            {section.options?.showSection && (
              <b2x.Section
                className={classnames('component-section')}
                id={section.options.id}
                key={section.contentSectionId}
                marginBottom={{
                  lg: section.options.marginBottom?.lg as b2x.MarginSize,
                  md: section.options.marginBottom?.md as b2x.MarginSize,
                  sm: section.options.marginBottom?.sm as b2x.MarginSize,
                  xl: section.options.marginBottom?.xl as b2x.MarginSize,
                  xs: section.options.marginBottom?.xs as b2x.MarginSize,
                  xxl: section.options.marginBottom?.xxl as b2x.MarginSize,
                }}
              >
                {demo && componentName && <ComponentName componentName={componentName} />}
                {component}
              </b2x.Section>
            )}
          </React.Fragment>
        );
      })}
    </Page>
  );
};

interface ComponentNameProps {
  componentName: string;
}

const ComponentName = ({ componentName }: ComponentNameProps) => (
  <b2x.Div background="#efefef" paddingY={4} textAlign={'center'}>
    <Container>
      <b2x.H3 margin={0}>
        <strong>{componentName}</strong>
        <br />
        <Icon name={'arrow-down-bold'} size={20} />
      </b2x.H3>
    </Container>
  </b2x.Div>
);
