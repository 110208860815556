import './SliderA.scss';

import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';

export interface SliderAContentType
  extends b2x.contentTypes.SwiperContentSection<{
    asset?: b2x.contentTypes.AssetContentSectionV2;
    copy?: {
      content?: string;
      cta?: b2x.contentTypes.CtaContentSection;
      disclaimer?: string;
      options?: {
        alignment?: string;
        background?: {
          desktop?: string;
          mobile?: string;
        };
        size?: string;
        textColor?: string;
      };
      title?: string;
    };
  }> {}
interface SliderAProps extends SliderAContentType {}

export const SliderA = ({ options, slides }: SliderAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  return (
    <b2x.Div className="slider-a">
      <b2x.EqualHeight>
        <b2x.SwiperContext>
          {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
            <b2x.SwiperFromContent
              {...options}
              navigation={{ custom: true }}
              pagination={{ clickable: true, custom: false }}
              parallax
              slides={slides?.map(({ asset, contentSectionId, copy }, index) => {
                const alignment =
                  copy?.options?.alignment === 'end'
                    ? 'end'
                    : copy?.options?.alignment === 'center'
                    ? 'center'
                    : 'start';

                const size: b2x.ColSize =
                  copy?.options?.size === 'small'
                    ? 4
                    : copy?.options?.size === 'large'
                    ? 8
                    : copy?.options?.size === 'extra-large'
                    ? 12
                    : 5;
                return (
                  <b2x.EqualHeightElement key={contentSectionId} name="slide">
                    <b2x.Div style={{ position: 'relative' }}>
                      {asset?.type && (
                        <b2x.Div className="asset-container">
                          <b2x.ConditionalWrapper
                            condition={copy?.cta !== undefined}
                            wrapper={
                              <b2x.router.Link
                                to={
                                  copy?.cta?.to?.href
                                    ? copy.cta.to.href
                                    : copy?.cta?.to?.code && getPagePath(copy.cta.to.code)
                                }
                              />
                            }
                          >
                            <b2x.AssetV2 {...asset} fluid />
                          </b2x.ConditionalWrapper>
                        </b2x.Div>
                      )}
                      <b2x.Div
                        alignItems={'center'}
                        background={isMobile ? copy?.options?.background?.mobile : undefined}
                        className="copy-container"
                        display="flex"
                        style={{
                          color: !b2x.untilBreakpoint('md', currentBreakpoint) ? copy?.options?.textColor : undefined,
                        }}
                      >
                        <Container>
                          <b2x.Row justifyContent={{ lg: alignment, xs: 'center' }}>
                            <b2x.Col size={{ lg: size, xs: 12 }}>
                              <b2x.Div
                                className="main-text-container"
                                paddingBottom={{ lg: 0, xs: 3 }}
                                paddingTop={{ lg: 0, xs: 3 }}
                                textAlign={{ lg: alignment, xs: 'center' }}
                              >
                                <b2x.Div
                                  background={!isMobile ? copy?.options?.background?.desktop : undefined}
                                  padding={{ lg: !isMobile && copy?.options?.background?.desktop ? 5 : 0 }}
                                >
                                  <b2x.P
                                    className="title"
                                    data-swiper-parallax="-400"
                                    data-swiper-parallax-duration="500"
                                    marginBottom={{ lg: 3, xs: 2 }}
                                  >
                                    {b2x.formatHtml(copy?.title)}
                                  </b2x.P>
                                  {copy?.content && (
                                    <b2x.P
                                      className="subtitle"
                                      data-swiper-parallax="-400"
                                      data-swiper-parallax-duration="550"
                                      marginBottom={copy.cta?.label ? { lg: 4, xs: 3 } : 0}
                                    >
                                      {b2x.formatHtml(copy.content)}
                                    </b2x.P>
                                  )}
                                  {copy?.cta && (
                                    <b2x.CtaFromContent
                                      {...copy.cta}
                                      data-swiper-parallax="-400"
                                      data-swiper-parallax-duration="600"
                                    />
                                  )}
                                  {copy?.disclaimer && (
                                    <b2x.P className="extra-small" marginBottom={0} marginTop={{ lg: 4, xs: 3 }}>
                                      {b2x.formatHtml(copy.disclaimer)}
                                    </b2x.P>
                                  )}
                                </b2x.Div>
                              </b2x.Div>
                            </b2x.Col>
                          </b2x.Row>
                        </Container>
                      </b2x.Div>
                    </b2x.Div>
                  </b2x.EqualHeightElement>
                );
              })}
            >
              <b2x.Div
                className="slider-arrows"
                display={{ lg: 'block', xs: 'none' }}
                style={{ pointerEvents: 'none', zIndex: 2 }}
              >
                {/* <Container> */}
                {b2x.appConfig.icons?.slider?.arrow?.left && isIconName(b2x.appConfig.icons.slider.arrow.left) && (
                  <b2x.Div
                    className="slider-arrow-container slider-arrow-left-container"
                    display={swiper?.isBeginning ? 'none' : 'block'}
                    style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                  >
                    <Button innerRef={navigationPrevElRef} variant="blank">
                      <Icon
                        className="slider-arrow-left text-dark"
                        name={b2x.appConfig.icons.slider.arrow.left}
                        size={50}
                      />
                    </Button>
                  </b2x.Div>
                )}
                {b2x.appConfig.icons?.slider?.arrow?.right && isIconName(b2x.appConfig.icons.slider.arrow.right) && (
                  <b2x.Div
                    className="slider-arrow-container slider-arrow-right-container"
                    display={swiper?.isEnd ? 'none' : 'block'}
                    style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                  >
                    <Button innerRef={navigationNextElRef} variant="blank">
                      <Icon
                        className="slider-arrow-right text-dark"
                        name={b2x.appConfig.icons.slider.arrow.right}
                        size={50}
                      />
                    </Button>
                  </b2x.Div>
                )}
                {/* </Container> */}
              </b2x.Div>
            </b2x.SwiperFromContent>
          )}
        </b2x.SwiperContext>
      </b2x.EqualHeight>
    </b2x.Div>
  );
};
